<template>
  <div class="p-3">
    <appCheckAcademicQualificationView
      :propsData1="getUserFromId"
      :propsData2="getAcademicQualification"
    ></appCheckAcademicQualificationView>
  </div>
</template>
<script>
import appCheckAcademicQualificationView from "../../components/checkAcademicQualification/checkAcademicQualificationView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "CheckAcademicQualificationView",
  components: {
    appCheckAcademicQualificationView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters([
      "getSettingAll",
      "getAcademicQualification",
      "getUserFromId",
      "getNotesList",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchAcademicQualification",
      "fetchUserFromId",
      // "fetchNotesList",
      // "fetchNotesPublicList"
    ]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchAcademicQualification(this.projectId);
    //this.fetchNotesList({ checkId: this.checkId, projectId: this.projectId });
  },
};
</script>

